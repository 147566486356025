import * as type from 'constants/action_types/accounts/amazonSellerAccounts'
import {
  AMAZON_SELLER_KEYS_SYNC_STEPS,
  PPC_SYNC_STATUS
} from 'constants/account'

export const initialState = {
  data: {
    merchant_id: '',
    primaryMarketplaceId: '',
    region: null, // needed for retry seller sync flow
    spapi_oauth_code: '',
    sellerAccountId: '' // needed for the PPC sync on account and other pages
  },
  ui: {
    activeModal: null, // one of AMAZON_SELLER_KEYS_SYNC_STEPS modals
    previousModal: null, // needed for tutorial modal to go back to previous modal
    ppcSyncStatus: PPC_SYNC_STATUS.initial // storing this for the PPC sync modal
  }
}

function sellerAccountSync(state = initialState, action) {
  switch (action.type) {
    case type.SELLER_ACCOUNT_SYNC_SHOW_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeModal: action.payload.modal,
          previousModal: state.ui.activeModal
        }
      }
    case type.SELLER_ACCOUNT_SYNC_CLOSE_MODAL:
      return {
        ...state,
        data: initialState.data,
        ui: initialState.ui
      }
    case type.SELLER_PPC_ACCOUNT_SYNC_SHOW_MODAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          activeModal: AMAZON_SELLER_KEYS_SYNC_STEPS.accountSetup,
          ppcSyncStatus: action.payload.ppcSyncStatus
        },
        data: {
          ...state.data,
          sellerAccountId: action.payload.sellerAccountId
        }
      }
    case type.ACCOUNT_SELLER_SYNC_SET_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export default sellerAccountSync
